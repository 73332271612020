import './App.css';
import 'react-circular-progressbar/dist/styles.css';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, SpeedDial, SpeedDialAction, TextField, Typography } from '@mui/material';
import Habit from './components/Habit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { CirclePicker } from 'react-color';

const speedDialActions = [
  { icon: <AddIcon />, name: 'New habit' },
  { icon: <DeleteIcon />, name: 'Delete habit' },
];

function App() {
  const [habitIDs, setHabitIDs] = useState(() => {
    const IDJSON = localStorage.getItem('habitIDs');
    const IDParsed = JSON.parse(IDJSON);

    return IDParsed || [];
  })

  const [newHabitOpen, setNewHabitOpen] = useState(false);
  const [newHabitName, setNewHabitName] = useState("");
  const [newHabitGoal, setNewHabitGoal] = useState(100);
  const [deleteMode, setDeleteMode] = useState(false);
  const [habitsToDelete, setHabitsToDelete] = useState([]);
  const [newHabitColor, setNewHabitColor] = useState('#03A9F4')

  const getNewID = () => {
    let tempHabits = [...habitIDs];

    tempHabits.sort((a, b) => a - b);

    let lowestFreeID = 0;

    for(let i = 0; i < tempHabits.length; i++) {
      if(tempHabits[i] === lowestFreeID) {
        lowestFreeID++;
      }
    }

    return lowestFreeID;
  }

  const handleNewHabitDialogClose = () => {
    setNewHabitOpen(false);
    setNewHabitName("");
    setNewHabitGoal(100);
  }

  const handleCreateNewHabit = () => {
    let newID = getNewID();
    let habitIDsCopy = [...habitIDs];
    habitIDsCopy.push(newID);

    localStorage.setItem(`habit${newID}`, JSON.stringify({
      progress: 0,
      min: 0,
      max: newHabitGoal,
      name: newHabitName,
      id: newID,
      color: newHabitColor
    }))
    localStorage.setItem('habitIDs', JSON.stringify(habitIDsCopy))
    setHabitIDs(habitIDsCopy)


    handleNewHabitDialogClose();
  }

  const handleDeleteHabit = (habitID, checked) => {
    let tempHabitsToDelete = [...habitsToDelete];

    if(checked) {
      tempHabitsToDelete.push(habitID)
    } else {
      const index = tempHabitsToDelete.indexOf(habitID);
      if(index > -1) {
        tempHabitsToDelete.splice(index, 1);
      }
    }

    setHabitsToDelete(tempHabitsToDelete);
  }

  const handleDeleteHabits = () => {
    let tempHabitIDs = [...habitIDs];

    habitsToDelete.forEach((habit) => {
      localStorage.removeItem(`habit${habit}`);
      const index = tempHabitIDs.indexOf(habit);
      if(index > -1) {
        tempHabitIDs.splice(index, 1);
      }
    });

    localStorage.setItem('habitIDs', JSON.stringify(tempHabitIDs));
    setHabitIDs(tempHabitIDs);

    setDeleteMode(false);
    setHabitsToDelete([]);
  }

  return (
    <div className="App">
      <Container sx={{marginTop: 2}}>
        { habitIDs.length > 0
          ? <Grid container spacing={4}>
            {habitIDs.map((habit) => {
              return <Grid 
                item 
                xs={12} 
                sm={6} 
                md={4} 
                lg={3}
                key={habit}
              >
                <Habit 
                  id={habit}
                  deleteMode={deleteMode}
                  onDeleteMe={handleDeleteHabit}
                />  
              </Grid>
            })}
          </Grid>
          : <Typography variant='h2'>Create a habit below to get started</Typography>
        }
        

        {!deleteMode && <SpeedDial
            ariaLabel='SpeedDial menu'
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
            }}
            icon={<SpeedDialIcon/>}
          >
            <SpeedDialAction
              icon={<AddIcon/>}
              tooltipTitle='New habit'
              onClick={()=> {setNewHabitOpen(true)}}
            />
            <SpeedDialAction
              icon={<DeleteIcon/>}
              tooltipTitle='Delete habit'
              onClick={()=> {setDeleteMode(true)}}
            />
          </SpeedDial>
        }
        {deleteMode && <Button 
            variant='contained'
            color='primary'
            onClick={handleDeleteHabits}
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 'auto',
              left: 'auto'
            }}
            startIcon={habitsToDelete.length > 0 ? <DeleteIcon /> : null}
          >
            {habitsToDelete.length > 0 ? 'Confirm delete' : 'Cancel'}
          </Button>
        }
        
          
        <Dialog open={newHabitOpen} onClose={handleNewHabitDialogClose}>
          <DialogTitle>Create new habit</DialogTitle>
          <DialogContent>
            <TextField
              label="Habit name"
              fullWidth
              sx={{
                marginBottom: 3
              }}
              value={newHabitName}
              onChange={(e)=>{setNewHabitName(e.target.value)}}
              variant='filled'
            />
            <TextField 
              label="Goal"
              type='number'
              fullWidth
              variant='filled'
              value={newHabitGoal}
              onChange={(e)=>{setNewHabitGoal(e.target.value)}}
            />
            <div style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 20,
            }}>
              <CirclePicker 
                colors={[
                  '#B71C1C', '#4A148C', '#01579B', '#006064', '#F57F17', '#000000', 
                  '#D32F2F', '#7B1FA2', '#0288D1', '#0097A7', '#FBC02D', '#525252', 
                  '#F44336', '#9C27B0', '#03A9F4', '#00BCD4', '#FFEB3B', '#969696', 
                  '#E57373', '#BA68C8', '#4FC3F7', '#4DD0E1', '#FFF176', '#D9D9D9', 
                  '#FFCDD2', '#E1BEE7', '#B3E5FC', '#B2EBF2', '#FFF9C4', '#DFDFDF'
                ]}
                onChange={(color) => {setNewHabitColor(color.hex)}}
              />
            </div>
            <div style={{
              width: '100%',
              height: 28,
              marginTop: 20,
              backgroundColor: newHabitColor,
              borderRadius: 28
            }}>

            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNewHabitDialogClose}>Cancel</Button>
            <Button onClick={handleCreateNewHabit}>Confirm</Button>
          </DialogActions>
        </Dialog>
          
      </Container>
    </div>
  );
}

export default App;
