import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';

export default function ProgressCircle(props) {
    const {
        color,
        min,
        max,
        progress
    } = props;

    return (
        <CircularProgressbar 
            value={progress}
            minValue={min}
            maxValue={max}
            strokeWidth={20}
            styles={{
                path: {
                    stroke: color,
                    strokeLinecap: 'butt'
                },
                trail: {
                    stroke: '#eeeeee'
                }
            }}
        />
    )
}