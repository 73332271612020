import { Button, Checkbox, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ProgressCircle from './ProgressCircle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const habits = [{
    progress: 68,
    min: 0,
    max: 100,
    color: '#ee00ff',
    name: 'OP-1'
  },{
    progress: 28,
    min: 0,
    max: 100,
    color: '#ee0000',
    name: 'Ableton'
  },{
    progress: 8,
    min: 0,
    max: 100,
    color: '#e660ff',
    name: 'Gym'
  },{
    progress: 88,
    min: 0,
    max: 100,
    color: '#2e00ff',
    name: 'Job Application'
  },{
    progress: 48,
    min: 0,
    max: 100,
    color: '#eeff07',
    name: 'Coding'
  },
];

const getHabitByID = (habitID) => {
    const habitJSON = localStorage.getItem(`habit${habitID}`);
    const habitParsed = JSON.parse(habitJSON);

    return habitParsed || {}
}

export default function Habit(props) {
    const {
        id,
        deleteMode,
        onDeleteMe
    } = props;

    const [habitState, setHabitsState] = useState(getHabitByID(id));
    const [deleteMe, setDeleteMe] = useState(false);

    const handleIncrementClick = () => {
        const newHabitState = {
            ...habitState,
            progress: habitState.progress + 1,
        }

        setHabitsState(newHabitState);
        localStorage.setItem(`habit${id}`, JSON.stringify(newHabitState));
    }

    const handleDecrementClick = () => {
        const newHabitState = {
            ...habitState,
            progress: habitState.progress - 1,
        }

        setHabitsState(newHabitState);
        localStorage.setItem(`habit${id}`, JSON.stringify(newHabitState));
    }

    const handleDeleteMeChange = (e) => {
        setDeleteMe(e.target.checked);
        onDeleteMe(id, e.target.checked);
    }

    useEffect(() => {
        setDeleteMe(false);
    }, [deleteMode])
    

    return (
        <Grid container>
            <Grid item xs={12}>
                <ProgressCircle 
                    progress={habitState.progress}
                    min={habitState.min}
                    max={habitState.max}
                    color={habitState.color}
                />
            </Grid>
            <Grid item xs={12} sx={{
                padding: 1
            }}>
                <Typography>{habitState.name}</Typography>
            </Grid>
            {
                deleteMode
                ? <Grid item xs={12}>
                    <Checkbox
                        checked={deleteMe}
                        onChange={handleDeleteMeChange}
                    />
                </Grid>
                : <>
                    <Grid item xs={2}></Grid>
                        <Grid item xs={3}
                        //     sx={{
                        //         backgroundColor: habitState.color,
                        //         borderRadius: 500
                        // }}
                        >
                            {/* <IconButton 
                                aria-label="decrement"
                                onClick={handleDecrementClick}
                                disabled={habitState.progress <= habitState.min}
                            >
                                <RemoveIcon/>
                            </IconButton> */}
                            <Button 
                                startIcon={<RemoveIcon />} 
                                variant='contained'
                                sx={{
                                    borderRadius: 12,
                                    backgroundColor: habitState.color,
                                    '&:hover': {
                                        backgroundColor: habitState.color,
                                    },
                                    '& .MuiButton-startIcon': {
                                        marginLeft: 0,
                                        marginRight: 0,
                                    }
                                }}
                                onClick={handleDecrementClick}
                                disabled={habitState.progress <= habitState.min}
                            />
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={3}>
                            <Button 
                                startIcon={<AddIcon />} 
                                variant='contained'
                                sx={{
                                    borderRadius: 12,
                                    backgroundColor: habitState.color,
                                    '&:hover': {
                                        backgroundColor: habitState.color,
                                    },
                                    '& .MuiButton-startIcon': {
                                        marginLeft: 0,
                                        marginRight: 0,
                                    }
                                }}
                                onClick={handleIncrementClick}
                                disabled={habitState.progress >= habitState.max}
                            />
                        </Grid>
                    <Grid item xs={2}></Grid>
                </>
            }
            <Grid item xs={12} sx={{
                padding: 1
            }}></Grid>
        </Grid>
    )
}