import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#9C27B0',
      contrastText: 'rgba(255,255,255,1)',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#f6f6f6',
      paper: '#f6f6f6',
    },
    text: {
      primary: 'rgba(30,30,30,0.87)',
      secondary: 'rgba(30,30,30,0.54)',
      disabled: 'rgba(255,255,255,0.38)',
      hint: 'rgba(255,255,255,0.38)',
    },
  },
});

export default theme;